<template>
  <div>
    <el-table
      :data="fileList"
      row-key="id"
      border
      style="width: 100%"
      v-loading="loading"
      @cell-dblclick="$parent.next"
      :cell-style="cellStyle"
    >
      <el-table-column
        label
        prop="isDir"
        key="isDir"
        width="51"
        align="center"
        class-name="file-icon-column"
      >
        <template slot-scope="scope">
          <img
            :src="$file.setFileImg(scope.row)"
            style="width: 30px; max-height: 30px; cursor: pointer"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="pathSuffix"
        header-align="center"
        width="400"
        label="名称"
      >
      </el-table-column>
      <el-table-column
        prop="modificationTime"
        header-align="center"
        align="center"
        width="200"
        label="修改时间"
      >
      </el-table-column>
      <!-- 展示目录图标或文件图标 -->
      <el-table-column
        header-align="center"
        align="center"
        label="图标"
        width="200"
      >
        <template slot-scope="scope">
          <i
            v-if="scope.row.directory && scope.row.active"
            class="el-icon-folder-opened"
          ></i>
          <i
            v-else-if="scope.row.directory"
            class="el-icon-folder"
          ></i>
          <i
            v-else
            class="el-icon-document"
          ></i>
        </template>
      </el-table-column>
      <!-- 展示文件或目录 -->
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="类型"
        width="200"
      >
        <template slot-scope="scope">
          <el-tag
            v-if="$parent.getFileType(scope.row) == '目录'"
            size="small"
            color="#FEFBE7"
          >目录</el-tag>
          <el-tag
            v-else-if="$parent.getFileType(scope.row) == '视频'"
            size="small"
          >视频</el-tag>
          <el-tag
            v-else-if="$parent.getFileType(scope.row) == '图片'"
            size="small"
            type="warning"
          >图片</el-tag>
          <el-tag
            v-else
            size="small"
            type="success"
          >文件</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="mapping"
        header-align="center"
        align="center"
        label="映射"
        width="370"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="$parent.next(scope.row)"
            v-if="scope.row.directory == true"
          >进入</el-button>
          <el-button
            type="text"
            size="small"
            @click="$parent.downloadHandle(scope.row)"
            v-if="scope.row.directory == false"
          >下载</el-button>
          <el-button
            type="text"
            size="small"
            @click="$parent.deleteHandle(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "FileTable",
  props: {
    // // 文件路径
    // filePath: {
    // 	required: true,
    // 	type: String
    // },
    // 文件列表
    fileList: {
      required: true,
      type: Array,
    },
    // 文件加载状态
    loading: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
      cellStyle(data) {
      if (data.columnIndex === 1  && data.row.directory == true) {
        return "cursor:pointer;";
      }
    },
  }
};
</script>

<style>
</style>